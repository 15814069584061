<template>
<section class="Page">
  The page you are looking for does not exist.
</section>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>

</style>
